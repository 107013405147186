import { useRouteStore } from '~/store/routesStore'
import { getValues } from '~/utils/shop/general.js'
import { SHOP_ROUTES_NAMES } from '~/constants'

const { setRouteName } = useShopRouteName()

/**
 * Sets the aspItem query parameter based on aspParams or diamondType.
 * @param {Object} to - The destination route object.
 * @param {Object} aspParams - The ASP parameters object.
 * @param {Boolean} isDiamondItem - Flag indicating if the route is a diamond item.
 */
const setAspItemQuery = (to, aspParams, isDiamondItem) => {
  if (aspParams.item) {
    to.query.aspItem = aspParams.item
  }
  if (isDiamondItem) {
    to.query.aspItem = to.query.diamondType
  }
}

/**
 * Sets the diamondType query parameter based on aspParams.
 * @param {Object} to - The destination route object.
 * @param {Object} aspParams - The ASP parameters object.
 */
const setDiamondType = (to, aspParams) => {
  const item = aspParams.item || to.query.item
  const diamondType = item < 0 ? item : aspParams.diamondType || to.query.diamondType
  if (diamondType) {
    to.query.diamondType = diamondType
  }
}

/**
 * Sets the shape and shapes query parameters for specific subcategories.
 * @param {Object} to - The destination route object.
 * @param {Object} aspParams - The ASP parameters object.
 */
const setShapeQueries = (to, aspParams) => {
  if (aspParams.subcategory == 48 || to.query.aspItem == -4) {
    to.query.shape = to.query['gemshape'] || aspParams['gemshape']
    to.query.shapes = to.query['gemshapes'] || aspParams['gemshapes']
  }
}

/**
 * Sets the stoneType query parameter, with a default for specific subcategories.
 * @param {Object} to - The destination route object.
 * @param {Object} aspParams - The ASP parameters object.
 */
const setStoneTypeQuery = (to, aspParams) => {
  to.query['stoneType'] = aspParams['stone'] || to.query['stone'] || to.query['stoneType'] || null
  if (aspParams.subcategory == 14 || to.query.aspItem == -1) {
    to.query['stoneType'] = to.query['stoneType'] || 1
  }
}

/**
 * Sets the shapes query parameter based on stoneType and whether it's a diamond category or item.
 * @param {Object} to - The destination route object.
 * @param {Object} aspParams - The ASP parameters object.
 * @param {Boolean} isDiamondCategory - Flag indicating if the route is a diamond category.
 * @param {Boolean} isDiamondItem - Flag indicating if the route is a diamond item.
 */
const setShapesBasedOnStoneType = (to, from, aspParams, isDiamondCategory, isDiamondItem) => {
  const shapes = aspParams.shape || to.query['shapes'] || to.query['shape']
  if (shapes && !(isDiamondCategory || isDiamondItem)) {
    const stoneBasedShapes = getQueryShapes(to.query['stoneType'], from.query['stoneType'], shapes)
    to.query.shapes = stoneBasedShapes
  }
}

/**
 * Merges ASP parameters and route query values, then cleans up unnecessary query parameters.
 * @param {Object} to - The destination route object.
 * @param {Object} aspParams - The ASP parameters object.
 */
const mergeAndCleanQueries = (to, aspParams) => {
  const aspAndRouteQuery = { ...aspParams, ...getValues(to.query) }
  to.query = { ...aspAndRouteQuery, ...getShopQuery(aspAndRouteQuery) }

  if (to.query.diamondType == -3) {
    delete to.query.stone
    delete to.query.stoneType
  }
  if (to.query.item < 0) {
    delete to.query.item
  }

  if (to.query.aspItem) delete to.query.shape
  delete to.query.aspItem
  delete to.query.stone
}

/**
 * Determines and sets the route name based on the current category and query parameters.
 * @param {Object} to - The destination route object.
 * @param {Object} aspParams - The ASP parameters object.
 * @param {Boolean} isDiamondCategory - Flag indicating if the route is a diamond category.
 */
const determineAndSetRouteName = (to, aspParams, isDiamondCategory) => {
  let routeName = isDiamondCategory ? SHOP_ROUTES_NAMES.DIAMOND : SHOP_ROUTES_NAMES.CATEGORY
  const item = aspParams.item || to.query.item

  if (to.query.step) {
    routeName = to.query.step
  } else if (item > 0) {
    routeName = SHOP_ROUTES_NAMES.ITEM
    to.query.step = SHOP_ROUTES_NAMES.ITEM
  } else if (isDiamondCategory && to.query.opened) {
    routeName = SHOP_ROUTES_NAMES.DIAMOND_DETAILS
    to.query.step = SHOP_ROUTES_NAMES.DIAMOND_DETAILS
  }

  setRouteName(routeName)
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.client) return
  const routeStore = useRouteStore()

  const aspParamEntries = routeStore.currentRoute?.aspParams || []
  const aspParams = Object.fromEntries(aspParamEntries)

  const isDiamondItem = to.query.step === SHOP_ROUTES_NAMES.ITEM_DIAMOND
  const isDiamondCategory = aspParams['category'] == 7

  setAspItemQuery(to, aspParams, isDiamondItem)
  setDiamondType(to, aspParams)
  setShapeQueries(to, aspParams)
  setStoneTypeQuery(to, aspParams)
  setShapesBasedOnStoneType(to, from, aspParams, isDiamondCategory, isDiamondItem)
  mergeAndCleanQueries(to, aspParams)
  determineAndSetRouteName(to, aspParams, isDiamondCategory)

  console.log('aspToQuery.js', { ...to.query })
})
