import { getValues } from '~/utils/shop/general'
import { DEFAULT_STONE_TYPE, DEFAULT_DIAMOND_TYPE } from '~/constants/shop'
import { getGemShapes } from '~/utils/shop/gems.js'

export const getRawUrl = (query) => {
  const finalQuery = {
    iconsExpressShop: !!query['filter'],
    expressCategoryId: query['expressCategory'],
    style: query['style'],
    subcategories: query['subcategories']
      ? query['subcategories']
      : query['subcategory']
        ? [query['subcategory']]
        : null,
    shapes: query['shapes'] ? query['shapes'] : query['shape'] ? [query['shape']] : null,
    metals: query['metals'] ? query['metals'] : query['metal'] ? [query['metal']] : null,
    diamonds: query['diamonds'] ? query['diamonds'] : query['diamond'] ? [query['diamond']] : null,
    step: query['step'],
    color: query['colors'],
    stoneType: query['stone'],
    gemType: query['gem'],
    diamondType: query['diamondType'] || query['diamond-type'] || query['diamondtype'],
    carat: query['carat'],
    'min-carat': query['mincarat'] || query['min-carat'],
    clarity: query['clarities'],
    discountCode: query['discount'],
    stockNumber: query['stock'],
    'ring-size': query['ring-size'],
    quickShipping: query['quickShipping'],
    stoneModalType: query['stoneModalType'],
    sortBy: query['sortBy'],
    withMedia: query['media'],
    fancyColorId: query['fancycolor'],
    variation: query['variation'],
    resultsPerPage: query['resultsPerPage'] || 20,
  }

  return finalQuery
}

export const stringToQueryObject = (url) => {
  const queryString = url.split('?')[1]
  if (!queryString) return {}

  const searchParams = new URLSearchParams(queryString)
  return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}

export const processRawUrl = (rawUrl) => {
  let query = getValues({
    category: rawUrl.categoryId,
    isExpressShop: rawUrl.isExpressShop,
    subcategory: rawUrl.subcategories && rawUrl.subcategories[0],
    subcategories: rawUrl.subcategories,
    item: rawUrl.item,
    metal: rawUrl.metals && rawUrl.metals[0],
    metals: rawUrl.metals,
    shapes: rawUrl.shapes,
    diamond: Array.isArray(rawUrl.diamonds) ? rawUrl.diamonds.join(',') : rawUrl.diamonds,
    selectedDiamond: rawUrl.selectedDiamond,
    colors: rawUrl.color,
    stoneType: rawUrl.aspItem != -3 ? rawUrl.stoneType : DEFAULT_STONE_TYPE,
    clarity: rawUrl.clarity,
    gemType: rawUrl.gemType,
    diamondType: rawUrl.diamondType || (rawUrl.aspItem < 0 ? rawUrl.aspItem : DEFAULT_DIAMOND_TYPE),
    'discount-code': rawUrl.discountCode,
    stockNumber: rawUrl.stockNumber,
    ringSize: rawUrl['ring-size'],
    quickShipping: rawUrl.quickShipping ? 1 : 0,
    sort: rawUrl.sortBy,
    gemOrigin: rawUrl.gemOrigin,
    cut: rawUrl.cut && [rawUrl.cut],
    withMedia: rawUrl.withMedia ? 1 : 0,
    fancyColorId: rawUrl.fancyColorId ? Number(rawUrl.fancyColorId) : null,
    variation: rawUrl.variation,
    depth: rawUrl.depth,
    width: rawUrl.width,
  })

  if (rawUrl.categoryId == 12) {
    query.category = 1
    query.isExpressShop = true
  }

  if (query.isExpressShop && rawUrl.style) {
    query.subcategory = rawUrl.style
    query.subcategories = [rawUrl.style]
  }

  if (rawUrl['min-carat']) {
    query['min-carat'] = rawUrl['min-carat']
  } else if (rawUrl.carat) {
    let carat = parseFloat(rawUrl.carat).toFixed(2)
    query['min-carat'] = carat
    query['max-carat'] = carat
  }

  query['stoneType,diamondType,gemType,fancyColorId'] = [
    query.stoneType || 1,
    query.diamondType,
    query.gemType,
    query.fancyColorId,
  ].join(',')

  if (query['stoneType,diamondType,gemType,fancyColorId'] == '1,-1,,') {
    delete query['stoneType,diamondType,gemType,fancyColorId']
  }
  try {
    let _77dShopQuery = sessionStorage['77dShopQuery']
    if (_77dShopQuery) {
      let storageFilters = JSON.parse(_77dShopQuery)
      Object.keys(storageFilters).forEach((key) => {
        if (!query[key]) query[key] = storageFilters[key]
      })
    }

    // delete sessionStorage['77dShopQuery'] not needed anymore for new session
    delete sessionStorage['77dShopQuery']
  } catch (e) {
    console.error(e)
  }

  return query
}

export const getShopQuery = (query) => {
  const rawUrl = getRawUrl(query)
  const queryWithRawUrl = { ...query, ...rawUrl }

  return getValues({ ...rawUrl, ...processRawUrl(queryWithRawUrl) })
}

export const getQueryShapes = (toStoneType, fromStoneType, aspShapeOrQueryShapes) => {
  if (toStoneType == 2) {
    const gemShape = getGemShapes().find((s) => s.group.join(',') == aspShapeOrQueryShapes)

    return gemShape?.Id || aspShapeOrQueryShapes
  }
  return aspShapeOrQueryShapes
}

export const getShapeForBrowserQueryWithGemstone = (queryShapeBeforeRouteChange) => {
  const gemShapes = getGemShapes().map((shape) => ({ id: shape.Id, groupIds: shape.group.join(',') }))
  return gemShapes.find((shape) => shape.id == queryShapeBeforeRouteChange)?.groupIds
}
